<script lang="ts" setup>
import { getMainStory } from '~/lib/content/getMainStory'
import { getStoryApiUrlParams } from '~/lib/storyblok'
import { storyblokResolveLinks } from '~/lib/storyblokResolveLinks'
import type { Story } from '~/types/storyblok'
import { normalizePlpHero } from '~/types/storyblok/hero-plp'
import { type NormalizedProductListMediaCard, type ProductListMediaCard, normalizeProductListMediaCard } from '~/types/storyblok/product-list-media-card'
import { getEdgeClasses } from '~/lib/getEdgeClasses'
import { stripLanguageCodeFromUrl } from '~/lib/url'

const props = defineProps<Props>()
const storefrontStore = useStorefrontStore()
const { contentKey, currentStorefrontCode } = storeToRefs(storefrontStore)
const { storyblokContentVersion } = useStoryblokMode()
const nuxtApp = useNuxtApp()

interface Props {
  blok: ProductListMediaCard
}

const blok = toRef(props, 'blok')
const card = normalizeProductListMediaCard(blok.value)

const cleanLinkedStoryUrl = stripLanguageCodeFromUrl(card.ctaLink)
const { language } = useLanguage()

const storyblokApi = useStoryblokApi()
const storyblokUrlParams = getStoryApiUrlParams(cleanLinkedStoryUrl, {
  contentKey: contentKey.value,
  language,
  version: storyblokContentVersion.value,
})
const linkedStoryRequestsKey = computed(() => `story-${card.ctaLink}`)
const { data: story } = await useAsyncData(
  linkedStoryRequestsKey.value,
  async () => {
    const { data } = await storyblokApi.get(`cdn/stories`, { ...storyblokUrlParams })
    let story = getMainStory(data.stories, contentKey.value, currentStorefrontCode.value)

    if (!story)
      return null

    story = storyblokResolveLinks(story)
    return story
  },
  {
    deep: false,
    getCachedData: (key) => {
      return nuxtApp.payload.data?.[key]
    },
  },
)

function normalizeLinkedStory(story: Story | null, card: NormalizedProductListMediaCard) {
  if (!story)
    return
  if (story.content.component === 'category') {
    const hero = computed(() =>
      story.content.hero?.[0] ? normalizePlpHero(story.content.hero?.[0]) : undefined,
    )
    const text = computed(() => hero.value?.body || story.content.description)
    return {
      body: card.body || text.value,
      fontColor: card.fontColor || hero.value?.fontColor,
      backgroundColor: card.backgroundColor || hero.value?.backgroundColor,
      asset: card.asset || hero.value?.image,
      altText: card.altText || hero.value?.imageAltText,
    }
  }
  else {
    return {
      body: card.body,
      fontColor: card.fontColor,
      backgroundColor: card.backgroundColor,
      asset: card.asset,
      altText: card.altText,
    }
  }
}
const linkedStory = normalizeLinkedStory(story.value, card)
</script>

<template>
  <NuxtLink v-if="blok && linkedStory" :to="$url(card?.ctaLink)" class="media-card">
    <ImageResponsive
      v-if="linkedStory?.asset"
      provider="storyblok"
      :src="linkedStory?.asset"
      :alt="linkedStory?.altText"
      :lazy="false"
      object-fit="fill"
    />
    <div class="content" :class="[getEdgeClasses(card.edgeStyle)]" :style="{ 'background-color': linkedStory?.backgroundColor, 'color': linkedStory?.fontColor }">
      <p class="body">
        {{ linkedStory?.body }}
      </p>
      <span
        v-if="card.ctaLink"
        class="link"
      >
        {{ card.ctaLabel }}
      </span>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/rules/edges';

.media-card {
    display:flex;
    flex-direction: column;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.9rem;
    padding: 2.4rem;
}

.body {
    max-width: 48rem;
}

.link {
    font-weight: 700;
}

.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.2rem;
}

.wave {
  @include waveFixed;
  margin-top: -1.2rem;
}
</style>
